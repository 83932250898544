const APP_VERSION = "1.4.7-prod";

const state = {
  // STRING FIELDS
  packageVersion: APP_VERSION || "LOCAL",
  versionNum: localStorage.getItem("versionNum") || null, // makes sure the user is logged in even after
  versionBranch: localStorage.getItem("versionBranch") || null, // makes sure the user is logged in even after
  versionDate: localStorage.getItem("versionDate") || null, // makes sure the user is logged in even after
  accessToken: localStorage.getItem("access_token") || sessionStorage.getItem("access_token") || null, // makes sure the user is logged in even after
  // refreshing the page
  refreshToken: localStorage.getItem("refresh_token") || sessionStorage.getItem("refresh_token") || null,
  first_name: localStorage.getItem("first_name") || sessionStorage.getItem("first_name") || null,
  last_name: localStorage.getItem("last_name") || sessionStorage.getItem("last_name") || null,
  username: localStorage.getItem("username") || sessionStorage.getItem("username") || null,
  user_id: localStorage.getItem("user_id") || sessionStorage.getItem("user_id") || null,
  // countries: localStorage.getItem("countries") || sessionStorage.getItem("countries") || null,
  lang: localStorage.getItem("lang") || sessionStorage.getItem("lang") || "pl-PL",
  organization_id: localStorage.getItem("organization_id") || sessionStorage.getItem("organization_id") || null,
  default_raw_supplier_id:
    localStorage.getItem("default_raw_supplier_id") || sessionStorage.getItem("default_raw_supplier_id") || null,
  organization_country:
    localStorage.getItem("organization_country") || sessionStorage.getItem("organization_country") || null,
  organization_name: localStorage.getItem("organization_name") || sessionStorage.getItem("organization_name") || null,
  organization_district:
    localStorage.getItem("organization_district") || sessionStorage.getItem("organization_district") || null,
  organization_expires:
    localStorage.getItem("organization_expires") || sessionStorage.getItem("organization_expires") || null,
  invoice_info: localStorage.getItem("invoice_info") || sessionStorage.getItem("invoice_info") || null,

  // INTEGERS!
  authenticated:
    JSON.parse(localStorage.getItem("authenticated")) || JSON.parse(sessionStorage.getItem("authenticated")) || 0,
  remember: JSON.parse(localStorage.getItem("remember")) || JSON.parse(sessionStorage.getItem("remember")) || 0,

  // BOOLEANS
  openSidebar: false,
  premium: false,
  showConfirmMessage: true,
  loadingSpinner: false,
  is_complete_plate_manufacturer:
    JSON.parse(localStorage.getItem("is_complete_plate_manufacturer")) ||
    JSON.parse(sessionStorage.getItem("is_complete_plate_manufacturer")) ||
    false,
  is_raw_plate_manufacturer:
    JSON.parse(localStorage.getItem("is_raw_plate_manufacturer")) ||
    JSON.parse(sessionStorage.getItem("is_raw_plate_manufacturer")) ||
    false,
  is_issuing_authority:
    JSON.parse(localStorage.getItem("is_issuing_authority")) ||
    JSON.parse(sessionStorage.getItem("is_issuing_authority")) ||
    false,
  is_requires_contracts:
    JSON.parse(localStorage.getItem("is_requires_contracts")) ||
    JSON.parse(sessionStorage.getItem("is_requires_contracts")) ||
    false,
  is_staff: JSON.parse(localStorage.getItem("is_staff")) || JSON.parse(sessionStorage.getItem("is_staff")) || null,

  // Array OBJECTS
  permission_list:
    JSON.parse(localStorage.getItem("permission_list")) || JSON.parse(sessionStorage.getItem("permission_list")) || [],
  country_codes:
    JSON.parse(localStorage.getItem("country_codes")) || JSON.parse(sessionStorage.getItem("country_codes")) || [],
  manufacturer_codes:
    JSON.parse(localStorage.getItem("manufacturer_codes")) ||
    JSON.parse(sessionStorage.getItem("manufacturer_codes")) ||
    [],
  // FILTER AND SORT MAIN TABLES
  filterNplate: { pageNumber: 1, query: {} },
  filterOrder: { pageNumber: 1, query: {} },
  filterRaw: { pageNumber: 1, query: {} },
  filterScrap: { pageNumber: 1, query: {} },
  mutexTokenRefresh: false,
};
export default state;
